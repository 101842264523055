import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import ZvInsuranceFormData from "@/Interfaces/ZvInsuranceFormData";

@Component({
    name: 'ZvInsuranceModal',
    components: {
        Modal,
    }
})
export default class HealthInsuranceModal extends PopupForm<ZvInsuranceFormData> {
    protected endpoint = "healthinsurances";
    protected modelName: string = "healthinsurances";

    private get canSave(): boolean|undefined {
        return !!this.formData.name && !!this.formData.uzovi_code;
    }

}
