import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import ZvInsuranceModal from '@/components/ZvInsuranceModal/ZvInsuranceModal.vue';
import Popup from "@/components/popup/popup.vue";
import {default as PopupComponent} from "@/components/popup/popup";
import {Ref} from "vue-property-decorator";
import ZvInsuranceFormData from "@/Interfaces/ZvInsuranceFormData";
import apiClient from "@/apiClient";
import Vue from "vue";
import ZvInsuranceNamesIndex from "@/views/ZvInsuranceNames/ZvInsuranceNamesIndex.vue";
import HealthInsuranceIndex from "@/views/HealthInsuranceIndex/HealthInsuranceIndex.vue";

@Component({
    components: {
        Popup,
        OverviewTable,
        ZvInsuranceModal,
        ZvInsuranceNamesIndex,
        HealthInsuranceIndex
    },
})
export default class InsurancesIndex extends IndexView<ZvInsuranceFormData> {
    public tab = 0 as number;

}
